@mixin set-flex-order($element, $divs){
	@for $i from 1 through $divs{
		#{$element}:nth-child(#{$i}){
			order: #{$i};
		}
	}
}

@mixin set-second-to-third($element){// put last name on second position
	@media (max-width: $grid-float-breakpoint){ 
		#{$element}:nth-child(2){
			order:4;
		}
	}
	@media (max-width: $grid-float-breakpoint){ 
		#{$element}:nth-child(5){
			order:3;
		}
	}
}

$form-fields : ".form-fields>div";

#ticket-modal{
	@include set-flex-order($form-fields, 8);
	@include set-second-to-third($form-fields);
	@media(max-width: $grid-float-breakpoint){
		.no-available-seats h2{
			font-size: 1.5rem;
		}
	}
}

.events-module .event-thumb{
	@media (min-width: $grid-float-breakpoint){
		height: 100%;
	}
}
.event-thumb{

	@media (min-width: $grid-float-breakpoint){
		.base-padding-bottom{
			padding-bottom: 25px;
		}
	}

	border: 1px solid $border-color;
	cursor: pointer;
	
	.picture{
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		min-height: 250px;
		height: 100%;
		@media (min-width: $grid-float-breakpoint){
			min-height: 200px;
		}
	}
	h4{
		margin-bottom: 20px;
	}
	.h5{
		margin-bottom: 0;
	}
	.event-thumb-container {
		margin-right: 0px;
		margin-left: 0px;
		height: 100%;
	}
	.base-padding-bottom{
		padding-bottom: 25px;
		@media (min-width: 48em) {
			padding-bottom: 0;
		}
		@media (min-width: 62em) {
			padding-bottom: 25px;
		}
		@media (min-width: 75em) {
			padding-bottom: 0;
		}
	}
	.event-text {
		padding-left: 1rem;
	}
	.event-desc{
		padding-top: 1rem;
		padding-left: 2rem;
	}
	.date{
		border-right: 1px solid $border-color;
		.year{
			font-weight: 300;
			font-size: 4.2rem;
			line-height: .9;
			color: $gray;
			margin: 0;

		}
		& > div{
			padding-left: ($spacer); 
			padding-right: ($spacer);

			p{
				margin-bottom: ($spacer / 3);
			}
		}
	}
}
#loadMoreEvents {
	width: 80%;
	margin: 10px auto;
	@media (min-width: $grid-float-breakpoint){
		width: 40%;
	}
}
.event-filter {
	ul {
		list-style:none;
		padding: 0px;
		a {
			text-decoration: none;
			color: $black;
			&:hover { 
				color: $brand-primary;
			}
		}
		.is-active {
			width: 40%;
			border-bottom: solid $brand-primary;
		}
	}
}


.new-events {
	border: 1px solid lightgrey;
	overflow: hidden;
	width: 100%;
	margin-bottom: 1em;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	&-thumbnail, &-info, &-description {
		width: 100%;
		@media (min-width: $grid-float-breakpoint) {
			flex: 33.33%;
		}
	}

	&-info, &-description {
		padding: 1em;
		@media (min-width: $grid-float-breakpoint){
			padding: 0.5em;
		}
	}

	&-thumbnail {
		background-size: cover;
		background-position: center center;
		height: 250px;
		@media (min-width: $grid-float-breakpoint){
			height: auto;
		}
	}
	&-info {
		.date {
			border-right: 1px solid $border-color;
			.year{
				font-weight: 300;
				font-size: 4.2rem;
				line-height: .9;
				color: $gray;
			}
		}
		.h5 {
			margin-bottom: 0!important;
		}
	}
	&-description {
		align-items: center;
		display: flex;
		p {
			margin: 0!important;
		}
	}
}