.blog {
  .principal-title {
    color: $brand-primary;
  }
  .blog-list {
    .blog-item {
      border: 1px solid $gray-dark;
      padding : 0px;
      .image {
        width: 100%;
        height: auto;
      }
      .text-container {
        padding: 20px;
        .text {
          height: auto;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .show-text {
          height: auto;
        }
      }
    }
  }
  .btn {
    border : 0px;
    margin: 20px 0px;
  }
  .search-section {
    .opera-grid {
      padding-bottom: 120px;
      overflow: hidden;
    }
  }
}
.tag{
  display: inline-block;
  color: $brand-primary;
  border: 1px solid $brand-primary;
  background-color: $white;
  padding: 3px 8px 5px;
  margin-left: 5px;
  transition: .3s all;
  &:hover{
    color:  $white;
    background-color: $brand-primary;
    text-decoration: none;
  }
}
.blog-grid{
  .grid-item{
    padding-bottom: 20px;
  }
}