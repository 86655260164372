$header-height-on-mobile: 60px;
$header-height-on-desktop: 100px;

$max-width: 1440px;

$nav-bg-color: #fff;

$space-size: 16px;
$transitions: 0.3s ease-in-out all;

.header
{
	background-color: $nav-bg-color;
	
	height: $header-height-on-mobile;
	width: 100%;

	position: fixed;
	z-index: 51;

	@media (min-width: $grid-float-breakpoint)
	{
		height: $header-height-on-desktop;
	}
	
	&__wrapper
	{

		position: relative;
		
		height: 100%;
		width: 100%;
		max-width: $max-width;

		margin: 0 auto;
		padding: 0 ($space-size * 2);

		display: flex;
		align-items: center;
		justify-content: center;

		@media (min-width: $grid-float-breakpoint)
		{
			justify-content: space-between;
		}

	}

	&__holder {
		height: $header-height-on-mobile;
		width: 100%;
		@media (min-width: $grid-float-breakpoint)
		{
			height: $header-height-on-desktop;
		}
	}

	.nav_icon
	{
		transform: translateY(-50%);
		top: 50%;
		left: $space-size;
		position: absolute;
		@media (min-width: $grid-float-breakpoint)
		{
			display: none;
		}
	}

	.brand
	{

		display: inline-block;
		height: 75%;
		max-height: 60px;

		img
		{

			height: 100%;
			width: auto;

		}

	}

	.nav
	{

		background-color: $nav-bg-color;

		position: fixed;
		top: $header-height-on-mobile;
		left: -100%;

		height: 100vh;
		width: 100vw;
		padding-bottom: $header-height-on-mobile + $space-size;

		overflow-y: scroll;
		transition: $transitions;

		@media (min-width: $grid-float-breakpoint)
		{
			left: auto;
			top: auto;
			overflow-y: auto;
			position: relative;
			height: 100%;
			width: auto;
			padding-bottom: 0;

			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}

		&.open
		{
			left: 0;
			box-shadow: 0 30px 30px 5px rgba(0, 0, 0, 0.7);
			@media (min-width: $grid-float-breakpoint)
			{
				box-shadow: none;
			}

			
		}

		a, .nav__main__item__fa-plus {
			color: #787878;
			text-transform: uppercase;
			font-size: 14px;
			font-weight: normal;
			&:hover
			{
				color: #002f6c;
			}
		}

		&__social, &__main
		{
			list-style: none;
			padding: 0;
			margin: 0;
			height: 50%;
			
			@media (min-width: $grid-float-breakpoint)
			{
				align-items: center;
				display: flex;
			}
		}

		&__social
		{
			display: none;
			@media (min-width: $grid-float-breakpoint)
			{
				display: flex;
				li {
					display: inline-block;
					margin: 0 0.7em;
					.fa {
						font-size: 1.5em;
					}
				}
			}
			a {
				font-weight: bold;
				opacity: 0.7;
				transition: $transitions;
				&:hover {
					opacity: 1;
				}
			}
		}

		&__main
		{

			@media (min-width: $grid-float-breakpoint)
			{
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-end;
			}

			&__item
			{
				border-bottom: 1px solid #ccc;
				width: 100%;
				@media (min-width: $grid-float-breakpoint)
				{
					border: none;
					display: inline-block;
					width: auto;
				}

				&__has_submenu
				{
					position: relative;
					
					.nav__main__item__link
					{
						display: inline-block;
					}
					.nav__main__item__fa-plus
					{
						position: absolute;
						top: 1.5em;
						right: 5%;
					}
				}
				&:last-child
				{
					border: none;
				}

				&__link
				{

					display: block;
					padding: $space-size;

					@media (min-width: $grid-float-breakpoint)
					{
						padding: 0;
						margin: 0 0.85em;
					}

				}

				&__submenu
				{

					display: none;
					list-style: none;

					@media (min-width: $grid-float-breakpoint)
					{
						background-color: $nav-bg-color;
						display: block;
						position: fixed;
						top: $header-height-on-desktop;
						left: 0;
						width: 100%;
						padding: 0;
						visibility: hidden;
						opacity: 0;
						transition: $transitions;
						padding: $space-size * 2;

						&__item
						{
							display: inline-block;
						}
					}

					&__wrapper
					{
						width: 100%;
						max-width: $max-width;
						@media (min-width: $grid-float-breakpoint)
						{
							display: flex;
							justify-content: center;
							flex-wrap: wrap;
							margin: 0 auto;
						}
					}

					&.open
					{
						display: block;
					}

					&__link
					{
						display: block;
						padding: $space-size;
						
						@media (min-width: $grid-float-breakpoint)
						{
							display: inline-block;
							font-size: 18px!important;
						}

					}

					&__productions
					{
						display: none;
						@media (min-width: $grid-float-breakpoint) {
							border-top: 1px solid black;
							display: flex;
							padding-top: 1em;

							.h2.sliders_titles_wrapper {
								font-size: 22px!important;
							}
							a {
								display: block;
								width: 25%;
								margin: 0 0.4rem;
							}
							.production-module {
								height: 180px;
								background-position: center center;
								background-size: cover;
								& > .img-responsive{
									height: 100%;
									object-fit: cover;
								}
							}
						}
					}

				}

			}

		}
		
	}
}

.nav__main__item__has_submenu:hover .nav__main__item__submenu
{
	visibility: visible;
	opacity: 1;
}

