.bottom-line{
	position: relative;

	&:after{
		content: '';
		background: $border-color;
		width: 4px;
		height: 50px;
		display: block;
		margin: -20px auto 20px;
		position: relative;
		z-index: 50;
	}
}
.top-line{
	position: relative;

	&:before{
		content: '';
		background: $border-color;
		width: 4px;
		height: 50px;
		display: block;
		margin: -20px auto 20px;
		position: relative;
		z-index: 50;
	}
}
.strype{
	position: relative;
	z-index: 0;
	&:before{
		content: '';
		position: absolute;
		display: block;
		background-color: $border-color;
		top: 40%;
		bottom: 40%;
		left: 0;
		right: 0;
		z-index: -1;
	}
}
.strype-alt{
	position: relative;
	z-index: 0;
	&:before{
		content: '';
		position: absolute;
		display: block;
		background-color: attr(data-color);
		top: 40%;
		bottom: 40%;
		left: 0;
		right: 0;
		z-index: -1;
	}
}
.container-fluid{
	max-width: 1440px;
}