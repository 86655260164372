.pagination {
  position: relative;
  height: 40px;
  .is-inactive {
    display: none;
  }
  .pagination-item {
    svg {
      height: 40px;
    }
  }
  .left {
    position: absolute;
    left: 0px;
  }
  .right {
    position: absolute;
    right:0px;
  }
}