.btn{
  display: inline-block;
  padding: ($spacer / 2) ($spacer * 3);
  text-transform: uppercase;
  background: $brand-primary;
  color: $white;
  font-size: $font-size-xs;
  font-weight: bold;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  border: none;
  cursor: pointer;

  & + &{
  	margin-top: $spacer;
    @media (min-width: $menu-float-breakpoint){
      margin-left: $spacer;
    }
  }
  &:before{
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $black;
    transition: all .2s;
    opacity: 0;
    z-index: -1;
  }
  &:hover{
    text-decoration: none;
    color: $white;
    &:before{
      opacity: .3
    }
  }
}
.btn-xs{
  padding: ($spacer /3 ) ($spacer);
}
.btn-sm{
  padding: ($spacer / 3) ($spacer * 2);
}

.btn-white{
  background: $white;
  color: $brand-primary!important;
}

//
// Colors
//
.text-light{
  color: $white;
  &:hover{
    color: $white;
  }
}
.text-dark{
  color: $black;
  &:hover{
    color: $black;
  }
}
.text-sky {
  color: $brand-primary;
  &:before{
    background-color: $brand-primary;
  }
  &:hover{
    color: $brand-primary;
  }
}