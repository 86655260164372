.content-page {
  .image-full-container {
    color : $brand-primary;
    .image-full {
      width: 100%;
      position: relative;
      .row{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      h1 {
        font-size: 3.125rem;
        @media (min-width: $grid-float-breakpoint){
          font-size: 80px;
        }
        @media (min-width: $menu-float-breakpoint){
          font-size: 120px;
        }
      }
    }
  }
  .two-images {
    .image {
      height: 350px;
      background-size: cover;
    }
  }
  .people {
    width: 100%;
    .person {
      border: 1px solid;
      padding: 15px 15px;
      margin-bottom: 15px;
      text-align: center;
      height: 100%;
      
      & > img{
        max-width: 250px;
      }

      hr {
        box-sizing: border-box;
        width: 30px;
        border: 1px solid $gray-dark;
      }
      .h4{
        margin-bottom: .5rem
      }
      .error {
        display: none; 
      }
    }
  }
  
  .text-container {
    padding: 0px 10px;
    @media (min-width: $grid-float-breakpoint){
     padding: 0px 65px;
   }
   .btn {
    border : 0px;
    margin: 20px 0px;
  }
  .show-text {
    height: auto;
  }
}
}
.bleed-off{
  .image-container {
    background-size: 100%;
    width: auto;
    position: relative;
    background-repeat: no-repeat;
    @media (min-width: $menu-float-breakpoint){
      left: $outer-margin;
    }
  }
  @media (min-width: $menu-float-breakpoint){
    &.rever{
      .image-container {
        left: ($outer-margin * -1);
      }
    }  
  }
}