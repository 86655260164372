.gallery-thumb{
	@extend .base-padding-sm;
	overflow: hidden;
	@media (min-width: $grid-float-breakpoint){
		height: auto;
	}
	& > div{
		overflow: hidden;
		max-height: 250px;
	}
	img{
		display: block;
		min-height: 250px;
	}
	&:hover{
		text-decoration: none;
	}
}