.border {
  border: 1px solid $gray-dark;
  padding: 1.25em 3em;
  margin: 0.8em;
  @media (max-width: $screen-sm){
    padding: 0.5em;
    margin: 0;
  }
  
}

.support-section{
  @media (max-width: $screen-sm){
    label{
      text-align: center
    }
    .text-left{
      text-align: center;
    }
  }
}

#involvedForm{
  .form-check-label{
    width:100%;
  }
}

#ticketsSlider{

  #btn-prev-season-tickets{
    display:none;
  }

  textarea{
    width:100%;
  }

  #submitPayTicket{
    display:none;
  }

  .specialInput{
    display:none;
  }
  
  .formContainer{
    width:100%;
  }

  .startingAt{
    line-height: 1.575em;
  }

  .slides{
    
    margin-top: 10px;

    & > li{
      width: 100%;
      white-space: normal;
      vertical-align: top;  
    }
  }
  .btn{
    background-color: $dark-blue;
    &.black-background{
      background: $black;
    }
  }
  #seasonNav{
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    & > li{
      flex-grow: 1;
      @media (max-width: $screen-sm){
        width:30%;
      }
      text-align: center;
      position: relative;
      &:after{
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 1px solid $brand-primary;
        margin: 0 auto;
      }
      &.active:after{
        background-color: $brand-primary
      }
    }
  }
}
.footer-options {
  .disabled {
    pointer-events : none;
  }
}
.input-text {
  border: 1px solid $brand-primary;
  color: $brand-primary;
  padding-left: 10px;
}

.billing-info .input-text { 
  width: 100%;
}

label {
  font-size: 20px;
  font-weight: 300;
}
.black-background {
  background: $black;
}

.white-background {
  background: $white;
}
.choose-section {
  p {
    font-size: 15px;
  }

  .input-button {
    width: 101%;
    color:$black;
    margin-bottom: 0px;
    margin: 5px 0px;
    span {
      text-align:center;
      font-size: $font-size-base;
      padding: 4px 0px;
      display:block;
    }
    input {
      display: none;
    }
  }
  .input-title-1 {
    background-color: #9FBCB3;
    border:2px solid #9FBCB3;
  }
  .level-1 {
    border:2px solid #9FBCB3;
    input:checked + span {
      background-color:#9FBCB3;
    }
  }
  .input-title-2 {
    background-color: #8A957A;
    border:2px solid #8A957A;
  }
  .level-2 {
    border:2px solid #8A957A;
    input:checked + span {
      background-color:#8A957A;
    }
  }
  .input-title-3 {
    background-color: #EAC88D;
    border:2px solid #EAC88D;
  }
  .level-3 {
    border:2px solid #EAC88D;
    input:checked + span {
      background-color:#EAC88D;
    }
  }
  .input-title-4 {
    background-color: #BC685C;
    border:2px solid #BC685C;
  }
  .level-4 {
    border:2px solid #BC685C;
    input:checked + span {
      background-color:#BC685C;
    }
  }
  .input-title-5 {
    background-color: #7C3E30;
    border:2px solid #7C3E30;
  }
  .level-5 {
    border:2px solid #7C3E30;
    input:checked + span {
      background-color:#7C3E30;
    }
  }
}
.st0,
.st1,
.st2,
.st3,
.st4{
  transition: all .2s;
  transition-delay: .3s;
}
.svg-gray{
  fill: #ccc !important;
}
#lastForm{
  transform: translate(-4%,0);
}