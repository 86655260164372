.footer {
  margin-top: 45px;
  background-color: $brand-primary;
  font-weight: 300 !important;;

  .footer-info{
    a:hover{
      text-decoration: none;
    }
  }

  h5,.h5, h4, .h4{
    font-weight: 700;
  }
  .social-footer{
    font-size: $font-size-h4;
    a{
      margin-right: 20px;
      display: inline-block;
      &:hover{
        text-decoration: none;
      }
    }
  }
  a{
    color: $white;
  }
  .input-group { 
    display: flex;
    border: $white;
    border-style: solid;
    border-width: 4px;
    width: 100%;
    input {
      background-color: $brand-primary;
      border: transparent;
      color: $white;
      padding-left: 10px;
      flex-grow: 1
    }
    ::-webkit-input-placeholder {
      color: $white;
      margin-left: 10px;
    }
    a {
      border-radius: 0;
      text-align: center;
    }
  }
  .footer-copyright {
    margin: 22px auto 0px;
    font-size: $font-size-xs;
  }
  .sections-footer{
    @media (max-width: 480px){
      display:flex;
      flex-wrap: wrap;
      justify-content: center;

      &>a{
        width:50%;
      }
      .btn + .btn {
        margin-left: 0;
      }
      .btn{
        margin-top: 1rem;
      }
    }
  }
  
}