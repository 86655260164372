/**
 * (optional) define here the style definitions which should be applied on the slider container
 * e.g. width including further controls like arrows etc.
 */
 .slider {
  position: relative;
  overflow: hidden;
  width:100%;
 }

.frame {
  /**
   * (optional) wrapper width, specifies width of the slider frame.
   */

   position: relative;
   overflow: hidden;
   white-space: nowrap;
   backface-visibility: hidden;


   .slides {
    display: block;
    margin: 0;
    padding: 0;
    backface-visibility: hidden;
  }

  li {
    position: relative;
    display: inline-block;
    margin: 0;
    backface-visibility: hidden;
 }
}
.prev, .next {
  position: absolute;
  top: 45%;
  margin-top: -17px;
  display: block;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  height: 34px;
  width: 34px;
}

.next {
  right: 5px;
}

.prev {
  left: 5px;
}

.next svg, .prev svg {
  width: 25px;
  height: 34px;
  margin-left: 4px;
}

.slider-content {
  background-color: rgba(0,0,0,0.6);
  @media (min-width: $grid-float-breakpoint) {
    background-color: transparent;
  }
  .btn {
    padding: 0.5rem 1rem;
    @media (min-width: $grid-float-breakpoint) {
      padding: 0.5rem 3rem;
    }
  }
}


.season-slider .season-img-responsive {
  height: auto;
  width: auto;
  min-width: 100%;
  min-height: 100%;
}

.season-slider {
  .js_slide {
    height: 250px;
    @media (min-width: $grid-float-breakpoint) {
      height: 300px;
    }
  }
  .production-module {
    height: 100%;
    background-size: cover;
    background-position: center center;
  }
}

.fill-height {
  height: 100%;
}