.search-section {
    position: relative;
    .input-text {
      width: 100%;
      border:1px solid $brand-primary;
      color: $brand-primary;
      padding : 10px;
    }
    .search-button {
      margin: 10px;
      right: 0px;
      position: absolute;
    }
}