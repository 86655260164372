.theater {
  .production-module {
    border: 0px solid $white;
  }
  .base-margin {
    margin: 0px auto 35px;
  }
  .feature {
    border: 1px solid $black;
    height: 100%;
    padding-right: $gutter-width;
    padding-left: $gutter-width;
  }
  .inspection-image {
    height: 105px;
    background-size: cover;
  }

}
