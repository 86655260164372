.production-module{
	width: 100%;
	border: 1px solid $border-color;
	position: relative;
	z-index: 0;
	overflow: hidden;

	&.text-light:before{
		content: '';
		display: block;
		background: linear-gradient($transparent,fade-out($black, .4));
		position: absolute;
		top: 30%;
		bottom: 0;
		left: 0;
		right: 0;
	}
	&.text-dark:before{
		content: '';
		display: block;
		background: linear-gradient($transparent,fade-out($white, .4));
		position: absolute;
		top: 30%;
		bottom: 0;
		left: 0;
		right: 0;
	}

	& > .row{
		position: relative;
		padding: $spacer;
		z-index: 1;
		@media (min-width: 480px){
			position: absolute;
			left: $spacer;
			right: $spacer;
			top: 0;
			bottom: 0;
			z-index: 1;
		}
	}

	& > .img-responsive{
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: -1;
		max-width: inherit;
		width: 100%;
		display: block;
		&.text-left{
			right: 0;
		}
		&.text-right{
			left: 0;
		}
		&.text-center{
			right: 0;
		}
		@media (min-width: 480px){
			position: static;
			height: auto;
			max-width: 100%;
			z-index: 1;
			margin: 0 auto;
		}

	}
	.invisible {
		visibility: hidden;
	}
	&.thumb {
		@media (max-width: 360px){
			& > .img-responsive{
				height: 100%;
				width: auto;
			}
		}
		@media (max-width: $menu-float-breakpoint) {
			.h2,.h4,.h5{
				margin-bottom: 5px;
			}
			.h5{
				font-size: 14px;
			}
			.h4{
				font-size: 1rem;
			}
			.btn{
				font-size: 10px;
				margin: 5px;
				padding: 0.5rem 1.5rem
			}
		}
	}

	&.hero{

		&:before{
			content: none;
		}

		.gradient-mask{
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 80%;
			display: block;
			z-index: 0;
		}

		@media (min-width: $grid-float-breakpoint){
			& > .row{
				left: 10%;
				right: 10%;
			}
		}

		.h2{
			font-size: 3rem;
			line-height: 3rem;
			@media (min-width: 480px){
				font-size: 5rem;
				line-height: 5rem;
				font-weight: 300;
			}
			@media (min-width: $grid-float-breakpoint){
				font-size: 5.5rem;
				line-height: 5.5rem;
			}
			@media (min-width: $menu-float-breakpoint){
				font-size: 7.5rem;
				line-height: 7.5rem;
			}
		}
		.h4{
			font-size: 1.5rem;
			line-height: 1.5rem;
			font-weight: 300;
			@media (min-width: $grid-float-breakpoint){
				font-size: 1.833rem;
				line-height: 1.833rem;
			}
		}
		.h5{
			font-size: 1rem;
			line-height: 1rem;
			font-weight: 700;
			@media (min-width: $grid-float-breakpoint){
				font-size: 1.25rem;
				line-height: 1.25rem;
			}
		}
	}
	&.small{
		.h4{
			font-size: $font-size-sm;
		}
	}
}