.page-header-wrapper{
	@media (min-width: $grid-float-breakpoint){
		padding-right: 2rem;
		padding-left: 2rem;
	}
}
.page-header {
	margin-bottom: 65px;
	position: relative;
	.row{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
	h1 {
		font-size: 3.125rem;
		@media (min-width: $grid-float-breakpoint){
			font-size: 80px;
		}
		@media (min-width: $menu-float-breakpoint){
			font-size: 120px;
		}
	}
}