#mainSlider {
  li {
    width: 100%;
  }
}
#ticketsSlider {
  height: auto;
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
}
.season-slider {
  li {
    width: 100%;
    @media (min-width: $grid-float-breakpoint) {
      width: 550px;
      margin-right: $spacer;
    }
  }
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#static-modal.modal.modal-center {
  .modal-dialog {
    top: 50%;
    transform: translateY(-50%);
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.grecaptcha-badge {
  bottom: 130px !important;
}
