.hide{
    display:none;
}

.hide-xs{
    @media (max-width: $grid-float-breakpoint){
		display:none;
	}
}

@media (max-width: 480px){
    .small-padding{
        padding-left:0.5rem;
        padding-right:0.5rem;
    }
}

span.separator{
    display:block;
    padding: 0.25rem;
}