//
// Headings
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: $headings-margin-bottom / 2;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
  @media (min-width: $grid-float-breakpoint) {
    margin-bottom: $headings-margin-bottom;
  }
  small{
    font-size: 70%;
  }
}

h1, .h1 { font-size: $font-size-h1; }
h2, .h2 { font-size: $font-size-h2; }
h3, .h3 { font-size: $font-size-h3; }
h4, .h4 { font-size: $font-size-h4; }
h5, .h5 { font-size: $font-size-h5; }
h6, .h6 { font-size: $font-size-h6; }

.lead {
  font-size: $lead-font-size;
  font-weight: $lead-font-weight;
}

//
// Image
//
.img-responsive{
	@include img-fluid;
}

//
// Text Alignment
//
.text-left{ text-align: left }
.text-center{ text-align: center }
.text-right{ text-align: right }
//
// Text style
//
.light{ font-weight: 300 }
.bold{ font-weight: 700 }

//
//Color
//
.text-primary{
	color: $brand-primary;
}
.text-white{
	color : $white!important;
}

.base-table{
  width: 100%;
}
.min-title{
  margin-bottom: 5px;
}
.unstyle{
  list-style: none;
}
.table{
  @extend .base-padding;
  table{
    width: 100%;
  }
}
.error{
  color: red;
}
.small{
  font-size: 80%;
}