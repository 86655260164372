.past-repertoire{

  .principal-title{
    color: $dark-blue;
  }
  .search-section{

    h5{
      color: $black;
    }
    .unstyle{
      padding-left:0;
    }
  }
  #searchForm{
    .btn{
      background-color: $dark-blue;
    }
  }
  .past-season {
    border: 1px solid;
    padding: 15px 15px;
    margin-bottom: 15px;
    text-align: center;
    height: 100%;
    h3{
      margin-bottom: .3rem
    }
  }
}
