@mixin padding-spacer($parent, $topSpace, $bottomSpace) {
	#{$parent}{
		padding-top: $topSpace;
		padding-bottom: $bottomSpace;
		@media (min-width: $grid-float-breakpoint){
			padding-top: floor($topSpace * 1.25);
			padding-bottom: floor($bottomSpace * 1.25);
		}
	}
	#{$parent}-top{
		padding-top: $topSpace;
		@media (min-width: $grid-float-breakpoint){
			padding-top: floor($topSpace * 1.25);
		}
	}
	#{$parent}-bottom{
		padding-bottom: $bottomSpace;
		@media (min-width: $grid-float-breakpoint){
			padding-bottom: floor($bottomSpace * 1.25);
		}
	}

	#{$parent}-left{
		padding-left: $topSpace;
		@media (min-width: $grid-float-breakpoint){
			padding-left: floor($topSpace * 1.25);
		}
	}
	#{$parent}-right{
		padding-right: $bottomSpace;
		@media (min-width: $grid-float-breakpoint){
			padding-right: floor($bottomSpace * 1.25);
		}
	}
}

@mixin margin-spacer($parent, $topSpace, $bottomSpace) {
	#{$parent}{
		margin-top: $topSpace;
		margin-bottom: $bottomSpace;
		@media (min-width: $grid-float-breakpoint){
			margin-top: floor($topSpace * 1.25);
			margin-bottom: floor($bottomSpace * 1.25);
		}
	}
	#{$parent}-top{
		margin-top: $topSpace;
		@media (min-width: $grid-float-breakpoint){
			margin-top: floor($topSpace * 1.25);
		}
	}
	#{$parent}-bottom{
		margin-bottom: $bottomSpace;
		@media (min-width: $grid-float-breakpoint){
			margin-bottom:floor($bottomSpace * 1.25);
		}
	}
}
@include padding-spacer('.base-padding-xs', 5px, 5px);
@include padding-spacer('.base-padding-sm', 10px, 15px);
@include padding-spacer('.base-padding', 20px, 25px);
@include padding-spacer('.base-padding-md', 50px, 55px);
@include padding-spacer('.base-padding-lg', 80px, 85px);
@include padding-spacer('.base-padding-xl', 120px, 125px);
@include margin-spacer('.no-margin', 0, 0);

.center-block{
	margin-left: auto;
	margin-right: auto;
	display: block;
}
.center-inline{
	margin-left: auto;
	margin-right: auto;
	display: inline-block;
}
.xs-container-fluid{
	padding-right: 1rem;
	padding-left: 1rem;
}
