//Colors
$brand-primary: 			#002f6c !default;
$dark-blue: #222D53;

//Non-Colors
$black:						#000;
$white:						#fff;

$gray-dark:					#787878;
$gray:						#B8B8B8;
$gray-light:				#DDDDDD;
$gray-lighter:				#EAEAEA;
$transparent : transparent;


//General
$spacer: 					1rem !default;

//Fonts
$font-family-base: 			'Lato', Helvetica, Arial, sans-serif !default;

$font-size-base: 			1rem !default;
$font-size-lg:  			1.25rem !default;
$font-size-sm:   			.875rem !default;
$font-size-xs:   			.75rem !default;

$font-weight-normal: 		lighter !default;
$font-weight-bold:			bold !default;

$font-weight-base: 			$font-weight-normal !default;
$line-height-base: 			1.618 !default;

//Heddings
$font-size-h1: 				3.125rem !default;
$font-size-h2: 				2.5rem !default;
$font-size-h3: 				2.188rem !default;
$font-size-h4: 				1.563rem !default;
$font-size-h5: 				1.25rem !default;
$font-size-h6: 				1rem !default;

$headings-margin-bottom:	1.3rem !default;
$headings-font-family:		inherit !default;
$headings-font-weight:		300 !default;
$headings-line-height:		1.1 !default;
$headings-color:			inherit !default;

$lead-font-size:   			1.25rem !default;
$lead-font-weight: 			300 !default;

//Body Elements
$body-color: 				$black !default;
$body-bg: 					$white !default;
$border-color:				$gray-light !default;

//Menu
$menu-bg:					$white !default;
$menu-link-color:			$gray-dark !default;
$menu-link-sm-color:		$gray !default;
$menu-link-color-hover:		$brand-primary !default;
$menu-font-size:			$font-size-sm !default;

$dt-font-weight: 			700 !default;

//Links
$link-color: 				$brand-primary !default;
$link-hover-color: 			darken($brand-primary, .3) !default;
$link-decoration: 			none !default;
$link-hover-decoration:		underline !default;
$enable-hover-media-query:  false !default;

$table-cell-padding:		.75rem !default;
$text-muted: 				$gray-light !default;

//Slider


//

/*---FLEXBOXGRIX---*/
// Set the number of columns you want to use on your layout.
$grid-columns: 12 !default;
// Set the gutter between columns.
$gutter-width: 1rem !default;
// Set a margin for the container sides.
$outer-margin: 2rem !default;
// Create or remove breakpoints for your project
// Syntax:
// name SIZErem,
$breakpoints:
  sm 48em 46rem,
  md 62em 61rem,
  lg 75em 71rem !default;

$flexboxgrid-max-width: 1200px !default;

$grid-float-breakpoint:  48rem;

$menu-float-breakpoint:  62rem;

$mobile-width: 56rem;

//Font awesome path
$fa-font-path: '../fonts/' !default;

//media querries
$screen-sm: 48em !default;
$screen-md: 62em !default;
$screen-lg: 75em !default;
$screen-xlg: 125em !default;

//custom easing
$dnamic-ease:  	cubic-bezier(0.4, 0.0, 0.2, 1) !default;

//animation framework
$animation-time:  .4s;
$animation-ease: $dnamic-ease;