#frend {
  .frend-header {
    position: relative;
    height: 400px;
    background-size: cover;
    background-position: center;
    &:before{
      content: '';
      display: block;
      background: linear-gradient($transparent,$black);
      position: absolute;
      top: 50%;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .header-info {
      position: absolute;
      bottom: 10px;
    }
  }
  .modal-body {
    .frend-profile-view {
      position: relative;
      padding-right: 0px;
      padding-left: 0px;
      padding-bottom: 120.71428571%;
      // height: 350px;
      width: 100%;
      // top: -40%;
      top:-27%;
      background-size: cover;
      @media (max-width: $screen-md){
        // height: 425px;
        top:-28%;
      }
      @media (max-width: $screen-sm){
        top:0%;
      }
    }
    .body-description {
      padding: 62px 31px;
    }
  }
  .frend-search {
    background: $white;
    position: relative;
    top: 55px;
    border: 1px solid $gray-light;
  }

}
.opera-grid {
  padding-bottom: 120px;
  overflow: hidden;
  .grid-sizer, .grid-item  {
    width:33%;
    @media (max-width : $screen-sm) {
      width:50%;
    }
  }
  .grid-item {
    padding    : 10px 10px;
    background : $white;
    cursor     : pointer;
    overflow   : hidden;
    float      : left; 
   
    .grid-overlay {
      padding    : 15px;
      background : transparent;
      position   : relative;
      top        : 0;
      left       : 0;
      right      : 0;
      bottom     : 0;
      transition : $animation-time all $animation-ease;
      border: 1px solid $gray-light;
        // color      : $white;
        .grid-info { 
          h5, h4 {
            margin-bottom: 5px;
          }
        }
        .opera-team {
          display: none;
        }
        .desc{
          display  : none;
          position : relative;
          padding-left: 15px;

          &:before{
            content : '';
            width   : 5px;
            background: $white;
            height: 100%;
            top: 0;
            left: 0;
            display: block;
            position: absolute;
          }
        }
      }
      &:hover{
        .grid-overlay{
          opacity    : 1;
          background : fade-out($black, .3);
        }
      }
      &.is-expanded {
        width    : 100%;
        z-index  : 2;
        @media (min-width : $screen-sm) {
          width : 99%;
        }
        @media (min-width: $screen-md) {
          width : 66%;
        }
        .grid-overlay {
          left     : 0%;
          position : relative;
          opacity  : 1;
          color    : $black; 
          overflow : auto;
          @media (min-width : $screen-sm) {
            position: relative;
          }
          .grid-info {
            display: inline-flex;
            position: relative;
            width: 100%;
            height: 50px;
            h5 {
              position: absolute;
              right: 10px;
            }
            h4 {
              position: absolute;
              left: 10px;
              font-size: $font-size-h3;
            }
          }
          .desc{
            display: inline-flex;
            flex-wrap: wrap;

            .des-container {
              padding: 0px 10px;
              flex: 1 30%;
              @media (max-width : $screen-sm) {
                flex: 1 100%;
              }
            }
            
          }
          .opera-team {
            display: inline-flex;
            flex-wrap: wrap;
            
            .per-team {
              border: 1px solid $gray-light;
              padding : 15px;
              margin: 10px;
              flex: 1 30%;
              @media (max-width : $screen-sm) {
                flex: 1 100%;
              }
            }
          }
        }
        &:hover{
          .grid-overlay{
            background : transparent;
          }
        }
      }
    }
  }