.profile-test{

	background-color: red;

	&:before{
		content:'';
		padding-bottom: 78.260869565217%;
		position:relative;
		display:block; 
	}
}

.profile-view {
	position: relative;
	padding-right: 0px;
	padding-left: 0px;
	padding-top: 127.77%;
	border: 1px solid $gray-dark;
	width: 100%;
	background-image: url(../images/no_avatar.png);
	background-color: #CCC;	
	background-size: cover;
	color: $white;
	cursor: pointer;
	text-align: center;

	.modal-body &{
		position: relative;
		margin-top: 20px;
		@media (min-width: $grid-float-breakpoint){
			margin-top: -130px;
		}
	}
	&:before{
		content: '';
		display: block;
		background: linear-gradient($transparent,$black);
		position: absolute;
		top: 50%;
		bottom: 0;
		left: 0;
		right: 0;
	}
	.modal-body &:before{
		content: none;
	}
	.profile-info {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		hr {
			box-sizing: border-box;
			width: 30px;
			border: 1px solid $gray-dark;
		}
		h6 {
			font-weight: $font-weight-bold;
		}
	}

}
#ProfileSection {
	.container {
		position: relative;
		border: 1px solid $gray-dark;
		margin: 0px auto;
		.left-container {
			width: 100%;
			padding: 60px 0px 0px 40px;
			.group-info {
				border: 1px solid;
				.img-container {
					height: 420px;
					width: 100%;
					background-size: cover;
				}
				.info {
					padding: 0px 20px 0px;
					h5 {
						margin: 5px 0px;;
					}
					.title {
						font-weight: $font-weight-bold;
						margin: 25px 0px 5px;
					}
					i {
						margin: 0px 20px 20px 0px;
						color: $black;
					}
				}
			}
		}
		.right-container {
			margin-top: 120px;
			padding: 80px;
			h3 {
				margin: 65px -40px;
				font-weight: $font-weight-bold;
			}
			h5 {
				padding: 70px 0px;
			}
		}
		hr {
			position: absolute;
			right: 0;
			left: 0;
			z-index: -1;
		}
	}
}

.prof-container{

	@media (min-width: $screen-sm) and (max-width: $screen-md){
		padding:0;
		.profile-view{
			margin-top:0px;
		}
	}
}

.profile-section{
	
	.info{
		margin-top: -1px;
		position: relative;
		border: 1px solid $gray-dark;
		border-top: none;
		@extend .container-fluid;
	}

	@media (min-width: $screen-sm) and (max-width: $screen-md){
		margin-top:-10px;
		background-color: $white;
		.info{
			border:none;
		}
	}
}

.prof-description{
	
	h6{
		font-weight: bold;
	}

	@media (min-width: $screen-sm) and (max-width: $screen-md){
		border: 1px solid $gray-dark;
		
		padding-top: 12px;
		padding-bottom: 18px;
		padding: 12px 20px 18px 20px;
	}
}