.production {
  @media (min-width: $grid-float-breakpoint){
    padding-left: $outer-margin;
    padding-right: $outer-margin;
  }
  .production-module {
    border: 0px solid $white;
  }
  .wrapper {
    height: 120px;
    display: none;
  }
  .wrapper-visible {
    display: flex;
  }
  .navigation-production {
    z-index: 51;
    margin-bottom: 40px;
    padding-bottom: 15px;
    text-align: center;
    a {
      padding : 1rem;
    }
  }
  .navigation-production-fixed {
    position: fixed;
    top: 85px;
    left: 0px;
    right: 0px;
    @media (max-width: 480px){
      top:75px;
    }
    padding-bottom: 0;
    @media (min-width: 75em) {
      top: 104px;
    }
    .btn + .btn{
      margin-top: 0;

    }
  }
}


.text{
  
    p>span:nth-of-type(2){
      display:none;
    }

    .hide{
      display:none;
    }

    &.show-text{
      p>span:nth-of-type(2){
        display:inline;
        p{
          margin-top: 1.5rem;
          margin-bottom: 0;
        }
      }
      .hide{
        display:block;
      }
    }
}


#overview-module {
  .btn {
    margin: 15px 0px;
    border: 0px;
  }
}
.synopsis {
  position: relative;

  .synopsis-container {
    // div {
    //   display: none;
    // }
    .act-0 {
      display: block;
    }
    .count {
      display: none;
    }
  }
  button {
    border : none;
  }


  .div-img {
    border: 1px solid $border-color;
    position: relative;
    background-size: cover;
    height: 350px;
    width: 100%;
    @media (min-width: $grid-float-breakpoint){
      top:-50px;
      left:30px;
      height: 600px;

    }
  }

}

.video-wrapper{
  position: relative;
}
.video-line {
  position: absolute;
  top: 50%;
  bottom: 30%;
  left: 0;
  right: 0;
  z-index: -1;
}
.photos-container {
  .div-image {
    height: 210px;
    width: 310px;
    background-size: cover;
    margin-bottom: 15px;
  }
  iframe {
    height: 100%;
    width: 100%;
    padding-bottom: 15px;
  }
}
.music-cast-info{
  cursor: pointer;
  border: 1px solid;
  width: 100%;
  height: 100%;
  transition: all .2s;
  h5 {
    margin-bottom: 0px;
  }
  &:hover{
    background: $brand-primary;
    color: $white;
  }
}    
