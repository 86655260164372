.dropdown{
	transition: all .3s;
	border: 1px solid $border-color;
	& > .drop-handdler{
		cursor: pointer;
		padding-left: 1rem;
		padding-top: .5rem;
		position: relative;
		&:hover{
			color: $brand-primary;
		}
		&:before{
			content: '';
			display: block;
			right: 10px;
			top: 20px;
			position: absolute;
			border-top: 7px solid $body-color;
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			transition: all .3s;
		}
	}
	& > .drop-inner{
		height: 0;
		overflow: hidden;
		transition: all .3s;
	}
	&.active{
		& > .drop-inner{
			height: auto;
			overflow: hidden;
		}
		& > .drop-handdler:before{
			transform: rotate(180deg);
		}
	}
	& + .dropdown{
		border-top: none;
	}
	& .dropdown{
		border-right: none;
		border-left: none;
	}
}