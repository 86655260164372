.main-slider-wrapper{
  @media (min-width: $grid-float-breakpoint){
    padding: 0 2rem;
    max-width: 1440px;
    margin: 0 auto;
  }
}
.project {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &:nth-child(1) {
    z-index: 30;
  }
}

.project > .text{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  opacity: 0;
  transition: all .6s ease-in;
  transition-delay: 1.2s;

  & > .row{
    height: 100%;
    padding-top: $spacer;
    padding-bottom: $spacer;
  }

  .dark{
    color: $black;
  }

  .light{
    color: $white;
  }
}
.project.active > .text{
  opacity: 1;
}
//
// Typo
//
.project > .text{

  h1, .h1{
    font-size: 6rem;
    line-height: 6rem;
    font-weight: 300;
    @media (min-width: $grid-float-breakpoint){
      font-size: 6rem;
      line-height: 6rem;
    }
    @media (max-width: $screen-sm){
      font-size: 2rem;
      line-height: 2rem;
    }
  }
  h2, .h2{
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight: 300;
    @media (min-width: $grid-float-breakpoint){
      font-size: 2.5rem;
      line-height: 2.7rem;
    }
    @media (max-width: $screen-sm){
      font-size: 1rem;
      line-height: 1rem;
    }
  }
  h3, .h3{
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 300;
    @media (min-width: $grid-float-breakpoint){
      font-size: 1.833rem;
      line-height: 1.833rem;
    }
  }
  h4, .h4{
    font-size: 1.3rem;
    line-height: 1.3rem;
    font-weight: 700;
    @media (min-width: $grid-float-breakpoint){
      font-size: 1.563rem;
      line-height: 1.563rem;
    }
  }
  h5, .h5{
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 700;
    @media (min-width: $grid-float-breakpoint){
      font-size: 1.25rem;
      line-height: 1.25rem;
    }
  }
  h6, .h6{
    font-size: $font-size-sm;
    line-height: $font-size-sm;
    font-weight: 700;
    @media (min-width: $grid-float-breakpoint){
      font-size: $font-size-sm;
      line-height: $font-size-sm;
    }
  }
  p{
    font-size: $font-size-lg;
    line-height: $font-size-sm;
    // margin-bottom: 0;
  }

}
#mainSlider .slider_navigation_dots {
  margin: 0; 
  padding: 0; 
  text-align: center; 
  margin-top: 10px; 
  position: absolute;
  bottom: 30px;
  width: 100%;
  
  & > li {
    border: 2px solid $white; 
    border-radius: 5px; 
    display: inline-block;
    height: 10px;
    width: 10px;
    margin: 0 5px;
    cursor: pointer;
  }
  & > li.active {
    background-color: $white;
  }
  
}
.season-slider .slider_navigation_dots {
  margin: 0; 
  padding: 0; 
  text-align: center; 
  margin: 10px;
  
  & > li {
    border: 2px solid $black; 
    border-radius: 5px; 
    display: inline-block;
    height: 10px;
    width: 10px;
    margin: 0 5px;
    cursor: pointer;
  }
  & > li.active {
    background-color: $black;
  }
  
}

.package {
  // img {
  //   height: 250px;
  // }
  &:nth-of-type(1){
    padding-left:1em;
  }
  p {
    font-size: $font-size-xs;
  }
}
#seasonSlider .slider_navigation_dots {
  position: absolute;
  top: 40px;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  display: flex;
  & > li {
    border: 1px solid $brand-primary; 
    border-radius: 10px; 
    display: inline-block;
    height: 15px;
    cursor: pointer;
    & > p {
      position: relative;
      text-align: center;
    }
  }
  & > li.active {
    background-color: $brand-primary;
  }
  
}

.sliders_titles_wrapper {
  word-wrap: break-word;
  white-space: normal;
}