.connect_module{
	border: 1px solid $border-color;
	margin-top: ($gutter-width / 2);
	margin-bottom: ($gutter-width / 2);
	padding-left: $spacer;
	padding-right: $spacer;
	position: relative;
	text-align: left;
	overflow: hidden;
	transition: all .2s;
	z-index: 0;

	&:after{
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: transparent;
		display: block;
		content: '';
		z-index: -2;
	}


	&.text{
		&:before{
			position: absolute;
			top: 70%;
			bottom: 0;
			right: 0;
			left: 0;
			background-image:  linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 60%);
			display: block;
			content: '';
			z-index: 0;
		}
	}
	&.xxl{
		height: 343px;
		@media (min-width: $grid-float-breakpoint){
			height: 700px;
		}
	}

	&.lg{
		height: 343px;
	}
	&.md{
		height: 164px;
		& + &{
			margin-top: ($gutter-width );
		}
	}
	a{
		text-decoration: none;
	}
	.icon{
		position: absolute;
		bottom: $spacer;
		left: $spacer;
		font-size: $font-size-lg;
		text-align: center;
		line-height: 40px;
		border: 1px solid $brand-primary;
		color: $brand-primary;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		&:hover{
			text-decoration: none;
		}
	}

	.base{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: auto;
		z-index: -1;
		@media (min-width: $grid-float-breakpoint){
			width: auto;
			height: 100%;
		}

		& + .icon{
			color: $white;
			border-color: $white
		}

	}

	.h1{
		padding-top: 50px;
	}

	&:hover{
		color: $white;
		.icon{
			color: $white;
			border-color: $white;
			text-decoration: none
		}
		&:after{
			background-color: $brand-primary;
		}
		&:before{
			background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, $brand-primary 60%);
		}
	}
}
.link-social-media {
	color:$black; 
	text-decoration: none;
	&:hover{
		color: $white;
		text-decoration: none;
	}
}