.opera-form {

  .giftDescription {
    display:none;
  }

  .input-button {
    width: 100%;
    background-color:$white;
    color:$black;
    border:1px solid $gray-dark;
    margin-bottom: 0px;
    span {
      text-align:center;
      font-size: $font-size-base;
      padding: 4px 0px;
      display:block;
    }
    input {
      display: none;
    }
    input:checked + span {
      background-color:$brand-primary;
      color:$white;
    }
  }
  .span-error {
    position: relative;
    top: -3px;
    left: 5px;
  }
  .input-text {
    border:1px solid $black;
    width: 100%;
    padding: 4px 20px;
  }
  .input-text-block {
    border:1px solid $brand-primary;
  }
  .input-group{
    position: relative;
    p {
      position: absolute;
      left: 10px;
      padding: 4px 0px;
    }
    .is-active {
      color : $brand-primary;
    }
  }
  .input-group-card{
    position: relative;
    i {
      position: absolute;
      left: 10px;
      padding: 10px 0px;
    }
    input {
      padding: 5px 40px;
    }
  }
  select {
    width: 100%;
    height: 35px;
    border: 1px solid $black;
  }

  .btn-md {
    padding: 0.88888rem 2.1rem;
    font-size: 16px;
  }
  .field-errors {
    background-color: #DC3545;
    color: white;
    width: 70%;
    margin: 0 auto;
    height: auto;
    font-size: 16px;
    display: none;
    padding: 10px;
  }

  input:disabled {
    border-color: #e8e8e8;
  }

  input:enabled {
    border-color: black;
  }
  
  textarea:focus, input:focus{
    outline: none;
  }
  
  
}