.venue {
	background: rgba(255,255,255,0.85);
	position: relative;
	margin: 0 auto 35px;
	@media (min-width: $grid-float-breakpoint){
		margin: -50px auto 35px;
	}
	@media (min-width: $menu-float-breakpoint){
		margin: -130px auto 35px;
	}
	.venue-name{
		font-weight: $font-weight-bold;
	}
	h2, h4, h6, iframe {
		padding-top : 20px;
	}
	a {
		margin-bottom: 56px;
	}
	.bottom {
		position: absolute;
		bottom: 0;
	}
	iframe {
		height: 70%;
		width: 100%;
	}
}