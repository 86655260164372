@each $breakpoint in $breakpoints {
	$name: nth($breakpoint, 1);
	$size: nth($breakpoint, 2);
	$container: nth($breakpoint, 3);
	@media only screen and (min-width: $size) {
		.vissible-#{$name}-block{
			display: block;
		}
		.vissible-#{$name}-inline{
			display: inline;
		}
		.vissible-#{$name}-flex{
			display: flex;
		}
		.vissible-#{$name}-inline-block{
			display: inline-block;
		}
	}
	@media only screen and (max-width: $size) {
		.hidden-#{$name}-block{
			display: none;
		}
		.hidden-#{$name}-inline{
			display: none;
		}
		.hidden-#{$name}-flex{
			display: none;
		}
		.hidden-#{$name}-inline-block{
			display: none;
		}
	}
	@media only screen and (min-width: $size) {
		.hide-#{$name}-block{
			display: none;
		}
		.hide-#{$name}-inline{
			display: none;
		}
		.hide-#{$name}-flex{
			display: none;
		}
		.hide-#{$name}-inline-block{
			display: none;
		}
	}
}
.embed-container { 
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	margin: 2.3em 0;
}
.embed-container video,
.embed-container iframe,
.embed-container object,
.embed-container embed { 
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}