.job-oportunities {
  .jobs {
    .jobs-container {
      border: 1px solid $brand-primary;
      padding: 20px;
      margin: 20px;
      min-height: 350px;
      .text{
        p{
          display:none;
        }
        p>span:nth-of-type(2){
          display:none;
        }
        p:first-child{
          display:block;
        }

        &.show-text{
          p{
            display:block;
          }
          p>span:nth-of-type(2){
            display:inline;
          }
        }
      }
      
    }
  }
}